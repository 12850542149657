import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, Spinner } from 'reactstrap';
import httpClientPy from "../../../../utils/httpClientPy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock,} from "@fortawesome/free-solid-svg-icons";
import Button from '../../../shared/Button';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Select from 'react-select'

const RegSelecterModal = (props) => {
    const [loading, setLoading] = useState(true);
    const [regs, setRegs] = useState(null);
    const [tempRegs, setTempRegs] = useState(null);
    const [regsFileNames, setRegsFileNames] = useState([]);

    useEffect(() => {
        httpClientPy.get(`/geo/records?project_id=${props.project.id}&geotech=3`)
            .then((response) => {
                let holder = response.data;
                holder.sort((a, b) => (a.created_datetime < b.created_datetime) ? 1 : -1);
                setRegs(holder);
                setTempRegs(holder);
                let fileNames = holder.map((reg) => {
                    return {value: reg.original_file_name, label: reg.original_file_name};
                });
                setRegsFileNames(fileNames);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
         // use the selector function to resolve your field names by passing the sort comparitors
         const aField = selector(rowA)
         const bField = selector(rowB)
       
         let comparison = 0;
       
         if (aField > bField) {
          comparison = 1;
         } else if (aField < bField) {
          comparison = -1;
         }
       
         return direction === 'desc' ? comparison * -1 : comparison;
        });
    };

    const handleSort = (column, sortDirection) => {
        let data = [...regs];
        const sortedData = customSort(data, column.selector, sortDirection);
        setRegs(sortedData);
    };

    const filterRegsName = (selectedOption) => {
        if(selectedOption) {
            let filteredRegs = regs.filter((reg) => reg.original_file_name === selectedOption.value);
            setTempRegs(filteredRegs);
        } else {
            setTempRegs(regs);
        }
    }

    const columns = [
        {
          name: 'File name',
          selector: row => row.original_file_name,
          center: true,
          sortable: true,
          cell: (row) => row.original_file_name,
        },
        {
          name: 'Added',
          id: 'uploaded',
          selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
          width: '100px',
          center: true,
          sortable: true,
          cell: (row) => (
            <span className="badge rounded-pill darkGrey documentBadge">
              <span><FontAwesomeIcon icon={faClock} /></span>
              <span className="ms-1">
                {row.created_datetime
                  ? moment(row.created_datetime).format('DD/MM/YYYY')
                  : 'Unknown'}
              </span>
            </span>
          ),
        },
        {
            name: '',
            width: '150px',
            center: true,
            cell: (row) => {
              return (
                <>
                  <Button label={"Select"} className="btn btn-primary" onClick={() => handleSelectClick(row)} />
                </>
              );
            },
          }
    ];   

    const customStyles = {
        headCells: {
          style: {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        },
        rows: {
          style: {
            cursor: props.type === 2 ? 'default' : 'pointer',
          },
        },
        table: {
            zIndex: 0,
        },
    };

    const handleSelectClick = (row) => {
        props.updateSelectedRegName(row.original_file_name);
        props.toggleModal();
    }

    return (
        <>
            <ModalHeader className="" toggle={()=>props.toggleModal()}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    {props.title}
                </h4>
            </ModalHeader>
            <ModalBody
                >
                {!loading && regs ?
                    <>
                        {regs.length > 0 ?
                            <>  
                                <div className="d-flex mb-2 position-relative justify-content-between" style={{'z-index': 2}}>
                                    <h6 className="title-bold"></h6>
                                    <Select
                                        className="basic-single filter-record-select-sm text-center ms-2"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isClearable={true}
                                        name="filterRegs"
                                        isMulti={false}
                                        placeholder="Search for Regulation file name"
                                        onChange={filterRegsName}
                                        closeMenuOnSelect={true}
                                        isRtl={true}
                                        options={regsFileNames}
                                    />
                                </div>
                                <div className="position-relative" style={{ zIndex: 1 }}>
                                    <DataTable
                                        className="sticky-top bg-white text-center"
                                        pagination
                                        paginationPerPage={10}
                                        onSort={handleSort}
                                        searchable
                                        highlightOnHover
                                        defaultSortFieldId="uploaded" 
                                        defaultSortAsc={false}
                                        columns={columns}
                                        data={tempRegs}
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        :
                            <>
                                <div>You have no building codes uploaded yet, please upload some to check your project documents against.</div>
                            </>
                        }
                    </>
                    :
                        <div className='w-100 pt-3 text-center'>
                            <Spinner size="lg"/>
                        </div>
                }
            </ModalBody>
        </>
    )   
}

export default RegSelecterModal;