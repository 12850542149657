import { useEffect, useState} from "react";
import httpClientPy from "../../../utils/httpClientPy.js";
import { Card, CardHeader, CardBody} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faPersonDigging, faFilter, faCloudArrowDown, faCircleInfo, faArrowsRotate, faWandMagicSparkles, faFilePdf, faTrash, faCloudArrowUp, faXmark} from "@fortawesome/free-solid-svg-icons";
import BoreHoleLogsList from "../../map/dataTab/BoreHoleLogsList.js";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from 'react-select'
import Loader from "../../shared/Loader.js";
import UploadedGeotechList from "./UploadedGeotechList.js";
import DataPageUploader from "../../shared/uploaders/DataPageUploader.js"
import SearchResult from "../detailComponents/SearchResult.js" 


library.add(faCopy, faCheck, faCloudArrowDown, faPersonDigging);

const UploadsList = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  //boreHoleLogs is used to store the list of borehole logs associated to this user
  const [boreHoleLogs, setBoreHoleLogs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);

  //loading controls a loader for the list
  const [loading, setLoading] = useState(true);
  //modal controls whether the borehole details modal is displayed
  const [modal, setModal] = useState(false);


  //the following states provide details for the borehole details modal, maybe we can consolidate these into one state variable?
  const [SelectedDataItem, setSelectedDataItem] = useState(null);
  const [SelectedData, setSelectedData] = useState(null);

  const [downloadSelection, setDownloadSelection] = useState(null);
  const [chatEnabled, setChatEnabled] = useState(null);


  // set a state to check if a info is edited
  const [boreHoleInfoUpdate, setBoreHoleInfoUpdate] = useState(false);

  //selected items array to keep track of items for downloading
  const [selectedItems, setSelectedItems] = useState([]);

  const [projectNames, setProjectNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [docCategoryCount, setDocCategoryCount] = useState();

  const [chatMessages, setChatMessages] = useState([]);
  const [subpage, setSubpage] = useState(null);

  // variable to store if user wants all docs or geotech
  const [task, setTask] = useState(2);

  const navigateToProjectDetails = (id) => {
    navigate('/project/detail', { state: {id: props.project.id} });
}



  // useEffect(() => {
  //   const { state } = location;
  //   if(state != null) {
  //     const { project } = state;
  //     const { subpage } = state;
  //     setSubpage(subpage);
  //     if(project) {
  //       setProject(project);
  //     }
  //   }
  //   else{
  //     navigate('/projects');
  //   }
  // }, []);


  const fetchBoreHoleLogs = () => {

    return httpClientPy
      .get(`/geo/records?project_id=${props.project.id}&geotech=${props.pageView !== undefined ? props.pageView : 2}`)
      .then((result) => {
        // clone the initialDocCategoriesCount object
        setProjectNames([]);
        setFileNames([]);
        let docCategoriesCountTemp = [];
        docCategoriesCountTemp[0] = result.data.length;

        let holder = result.data
        holder.sort((a, b) => (a.created_datetime < b.created_datetime) ? 1 : -1)

        setBoreHoleLogs(holder);
        setFilteredDocs(holder);

        let project_names = []
        holder.forEach((item) => {
            let project_name = item.description ? item.description : 'Unknown Project'
            project_names.push({value: project_name.toLowerCase(), label: project_name, group: 'project_name'})
            // check if there is docCategoriesCountTemp[item.geotech + 1 and increment it if not set as 1
            if (docCategoriesCountTemp[item.geotech + 1] === undefined) {
              docCategoriesCountTemp[item.geotech + 1] = 1; 
            } else {
              docCategoriesCountTemp[item.geotech + 1] += 1;
            }
          
        })
        project_names = project_names.filter((item, index, self) => self.findIndex(t => t.value === item.value) === index)

        if(props.pageView===1){
          setProjectNames(project_names)
          setDocCategoryCount(docCategoriesCountTemp);
        }

        let file_names = []
        holder.forEach((item) => {
          if(item.geotech === props.pageView){
            if (item.is_private) {
              if (item.borehole_name) {
                file_names.push({value: item.borehole_name.toLowerCase(), label: item.borehole_name, group: 'file_name'})
              } else if (item.description) {
                file_names.push({value: item.description.toLowerCase(), label: item.description, group: 'file_name'})
              } else if(item.original_file_name){
                file_names.push({value: item.original_file_name.toLowerCase(), label: item.original_file_name, group: 'file_name'})
              }
              else {
                file_names.push({value: 'unknown project', label: 'Unknown Project', group: 'file_name'})
              }
            } else {
              file_names.push({value: item.reference.toLowerCase(), label: item.reference, group: 'file_name'})
            }
          }
        })
        file_names = file_names.filter((item, index, self) => self.findIndex(t => t.value === item.value) === index)
        setFileNames(file_names)
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(props.project) {
      fetchBoreHoleLogs();
    }
  }, [props.project, props.pageView]);


  function filterGeoRecordsByFileName(selections) {
    if (selections.length > 0) {
      let holder = []
      selections.forEach((item) => {
        if (item.group == 'project_name') {
          boreHoleLogs.forEach((geoRecord) => {
            if (geoRecord.description) {
              if (geoRecord.description.toLowerCase() === item.value) {
                holder.push(geoRecord)
              }
            } else {
              if (item.value === 'unknown project' && geoRecord.geotech === 1) {
                holder.push(geoRecord)
              }
            }
          })
        } else {
          boreHoleLogs.forEach((geoRecord) => {
            if (geoRecord.is_private) {
              if (geoRecord.borehole_name) {
                if (geoRecord.borehole_name.toLowerCase() === item.value) {
                  holder.push(geoRecord)
                }
              } else if (geoRecord.description) {
                if (geoRecord.description.toLowerCase() === item.value) {
                  holder.push(geoRecord)
                }
              } else if (geoRecord.original_file_name) {
                if (geoRecord.original_file_name.toLowerCase() === item.value) {
                  holder.push(geoRecord)
                }
              }
              else {
                if (item.value === 'unknown project') {
                  holder.push(geoRecord)
                }
              }
            } else {
              if (geoRecord.reference.toLowerCase() === item.value) {
                holder.push(geoRecord)
              }
            }
          })
        }
      })
      
      // remove duplicate item in holder
      holder = holder.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index)

      setFilteredDocs(holder)
      
      // let project_names = []
      // holder.forEach((item) => {
      //   if (item.description !== null) {
      //     project_names.push({value: item.description, label: item.description})
      //   }
      // })
      // project_names = project_names.filter((item, index, self) => self.findIndex(t => t.value === item.value) === index)
      // setProjectNames(project_names)
    } else {
        setFilteredDocs(boreHoleLogs);
    }
  }


  // for chat
  const toggle = () => {
    setModal(!modal);
  }

  useEffect(() => {
    if(boreHoleInfoUpdate) {
      fetchBoreHoleLogs()
      if(SelectedDataItem) {
        loadDataItem(SelectedDataItem)
      }
      setBoreHoleInfoUpdate(false)
    }
  }, [boreHoleInfoUpdate]);


  // for chat
  const loadDataItem = async (item) => {
    item.extraction_requested = true;
    setTask(2)
    setSelectedData(item)
    setModal(true);
    setChatEnabled(true)  
  };

  const recordCheck = async (item) => {
    item.extraction_requested = true;
    setSelectedData(item)
    setModal(true);
    setChatEnabled(true)
    setTask(3)  
  };




  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#800080',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#800080',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const groupedOptions = [
    {
      label: 'Projects',
      options: projectNames,
    },
    {
      label: 'Files',
      options: fileNames,
    },
  ];

  const handleChatClose = () => {
    setChatEnabled(false);
  }


  return (
    <>
      <div className='d-flex bg-white min-vh-100'>
        <div id="sidebarParent">
        </div>
        <div  className="document-list-page flex-fill">
          <Card className="h-100 border-0 ">
          <CardHeader style={{backgroundColor:'#fbfbfb'}}>
            <div className='py-2 w-100'>
            <div className="d-flex">
              <div style={{ width: '275px', flexShrink: 0 }}>
                <DataPageUploader
                  mode={props.pageView}
                  uploadUrl="/geo/record/upload"
                  onComplete={() => fetchBoreHoleLogs()}
                  projectId ={props.project.id}
                />
              </div>
              <div className="flex-grow-1 ps-3">
                <h3 className='title-bold ms-2'>{props.pageView === 0 ? "Project Documents & Plans" : props.pageView === 1 ? "Geotech Reports" : props.pageView === 3 ? "Building codes, specs and regulations" : "Standard CAD Details"}</h3>
                <div>
                  <Select
                    className="basic-multi-select filter-record-select-sm text-center ms-2"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="filterFileName"
                    isMulti={true}
                    placeholder="Search for a Project or File Name"
                    onChange={filterGeoRecordsByFileName}
                    components={{ ValueContainer }}
                    closeMenuOnSelect={false}
                    isRtl={true}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                  />
                </div>
              </div>
            </div>
            
            </div>
          </CardHeader>
          {loading === false ?
              <>
                {boreHoleLogs && props.project && props.project.id && boreHoleLogs.length > 0 ?
                  <>
                  {props.pageView !==1 ?
                    <CardBody className="overflow-auto" style={{ zIndex: 0 }}>
                      <BoreHoleLogsList 
                                      onViewDetails={(item)=>loadDataItem(item)} 
                                      data={filteredDocs} 
                                      loading={loading} 
                                      project={props.project}
                                      type={props.pageView}
                                      recordClicked={(item)=>loadDataItem(item)} 
                                      recordCheck={(item)=>recordCheck(item)} 
                                      boreHoleInfoUpdate={(status)=>setBoreHoleInfoUpdate(status)}
                                      />
                  
                    </CardBody>
                    :
                    <>
                      <UploadedGeotechList 
                        data={filteredDocs} 
                        project={props.project}
                        type={props.pageView}
                        boreHoleInfoUpdate={(status)=>setBoreHoleInfoUpdate(status)}
                      />
                  </>
                  }
              </>
            :
            <div className="container-flex">
              <div className="row">
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <h3><b>You've not uploaded any documents of this type yet</b></h3>
                    <div className="pt-2">Upload some PDF files first to get started, processing takes about 15 minutes per file.</div>
                </div>
              </div>
            </div>
            }
            </>
            :
            <div className="container-flex">
              <div className="row">
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <Loader loading={loading} size='large'/>
                </div>
              </div>
            </div>
          }
            {props.project && props.project.id &&
              SelectedData && SelectedData.file_path && chatEnabled &&
              <div className="container-flex">
                <SearchResult 
                  project={props.project} 
                  initMessage={null} 
                  historicalMsg={null}
                  lastId={null}
                  handleChatClose={()=>handleChatClose()}
                  task={task}
                  fileType={SelectedData.geotech}
                  selectedData={SelectedData}
                />
              </div>
              }
          </Card>
        </div>
      </div>
    </>
  );
};

export default UploadsList;



const ValueContainer = ({ children, ...props }) => {
  if (children[0] !== null) {
    const length = children[0].length
    let tmpChildren = [...children];
    if(length >= 2){
      tmpChildren[0] = `${length} options selected`
    }

    return (
      <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
    );
  } else {
    return (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  }
};